// ------------------------------------------------
// Site Name:
// Author:
// ------------------------------------------------

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

:root {
  color-scheme: light;
}

body,
html {
  margin: 0;
}

body {
  font-family: $main;
  background: linear-gradient(186deg, #2a2c2c 4.72%, #000 50.01%);

  color: #fff;
  margin: 0 auto;
  max-width: 1920px;
}

.app {
  position: relative;
  height: 100vh;
  overflow-x: hidden;
}

section {
  position: relative;
  padding: 30px 24px;

  @include XGA {
    padding: 36px 64px;
  }
}

.container {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1580px;
  padding: 4px 0;
}

.main-wrapper {
  font-family: $main;
  background-color: #2e2b45;
  color: #fff;
  margin: 0 auto;
  max-width: 1920px;
  font-family: $secoundary;
}
