@import "../../../styles/vars";

$darkorange: #e5451f;
$orange: #e86215;
$lightorange: #ed8002;

$clipPathCorner: polygon(
  0 0,
  100% 0,
  100% calc(100% - 16px),
  calc(100% - 16px) 100%,
  0 100%
);

.video-page {
  @include HD {
    background-size: 700px;
  }

  .glo-devices {
    width: 85%;
    height: auto;

    @include vHD {
      width: 305px;
      margin-top: 40px;
    }
    @include XGA {
      // margin-top: 0;
      width: 400px;
    }
    @include HD {
      margin-top: 0;
      width: 450px;
    }
  }
  .content-container {
    position: relative;
    @include vHD {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;

      width: 90%;
      margin: 0 auto;
    }
    @include XGA {
      gap: 50px;
    }

    .content {
      p {
        @include XGA {
          margin-top: 30px;
        }
      }
    }

    .description {
      border-top: 1px solid #555555;
      padding-top: 20px;
      margin-top: 20px;
      @include vHD {
        border-top: 0;
      }
    }

    p {
      @include XGA {
        font-size: 20px;
      }
      @include HD {
        font-size: 26px;
      }
      span {
        display: block;
        @include vHD {
          display: inline;
        }
      }
    }
  }

  .logo {
    position: absolute;
    top: 0;
    right: 0;
  }

  h2 {
    font-weight: 900;
    font-size: 24px;
    line-height: 36px;
    text-transform: uppercase;

    margin: 0;

    margin-bottom: 8px;

    @include vHD {
      margin-top: 36px;

      font-size: 34px;
      width: 100%;
      min-width: max-content;
    }
    @include XGA {
      width: 100%;
      // max-width: 500px;
      font-size: 44px;
      line-height: 100%;
      margin-top: 100px;
    }
    @include HD {
      width: 100%;
      // max-width: 635px;
      font-size: 64px;
      line-height: 100%;
    }
  }

  p {
    margin: 0;
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 20px;

    &.disclaimer {
      font-size: 12px;
      line-height: 16px;
      margin-top: 16px;

      @include XGA {
        margin-left: 10%;
      }

      @include HD {
        font-size: 15px;
        line-height: 20px;
      }
    }

    @include HD {
      font-size: 28px;
      line-height: 32px;
    }
  }

  video,
  .placeholder {
    display: block;
    margin: 0 auto;
    width: 100%;

    @include XGA {
      margin-top: 48px;
      width: 90%;
    }

    @include HD {
      margin-top: 64px;
    }
  }

  .placeholder {
    background-color: #1e1e1e;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 70%;
    background-image: url(../../../img/placeholder-bg.svg);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 30px 8px;

    @include vHD {
      aspect-ratio: 16/8;
    }

    h3 {
      font-size: 24px;
      line-height: 24px;
      margin: 0;
      font-weight: 600;
      text-transform: uppercase;

      @include HD {
        font-size: 80px;
        line-height: 90px;
      }
    }

    p {
      font-size: 16px;
      line-height: 20px;
      margin: 16px 0;

      @include HD {
        font-size: 28px;
        line-height: 32px;
      }
    }

    .button-wrapper {
      position: relative;
      @include XGA {
        margin-top: 24px;
      }
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 24px;
        height: 1px;
        background-color: $white;
        z-index: 1;
        pointer-events: none;
        transition: ease-in-out 0.3s;
      }
    }
    .button {
      appearance: none;
      display: block;
      padding: 12px 42px 12px 24px;
      font-size: 14px;
      font-weight: 700;
      line-height: 12px;
      text-decoration: none;
      border: none;
      cursor: pointer;
      user-select: none;
      text-align: center;
      clip-path: $clipPathCorner;
      position: relative;
      margin-left: 12px;
      flex-basis: 100%;
      background: #f6b900;
      color: #000;

      .button-inner {
        position: relative;
      }
    }
  }

  .video-holder {
    position: relative;

    video:hover + .re-play-icon {
      opacity: 0.8;
    }

    &.paused {
      video {
        cursor: pointer;
      }
    }

    &.paused .re-play-icon {
      &::before {
        content: url(./img/play.svg);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
      }
    }

    &.ended .re-play-icon {
      &::before {
        content: url(./img/replay.svg);
      }
    }
  }
}
