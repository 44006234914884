@import "../../../styles/vars";

$darkorange: #e5451f;
$orange: #e86215;
$lightorange: #ed8002;

.video-pagev2 {
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url(../../../img/bg-mobilev2.jpg);
  background-position: top right;

  @include vHD {
    background-size: 50%;
    background-image: url(../../../img/bg-desktopv2.jpg);
  }

  @include HD {
    background-size: 700px;
  }

  .logo {
    position: absolute;
    top: 0;
    right: 0;

    @include HD {
      top: 80px;
    }
  }

  h2 {
    font-weight: 900;
    font-size: 36px;
    line-height: 42px;
    text-transform: uppercase;
    font-style: italic;
    margin: 0;
    margin-top: 50vw;
    margin-bottom: 8px;

    @include vHD {
      margin-top: 36px;
      max-width: 60%;
    }

    @include HD {
      font-size: 48px;
      line-height: 54px;
      max-width: 800px;
      margin-top: 84px;
    }
  }

  p {
    margin: 0;
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 20px;

    &.disclaimer {
      font-size: 12px;
      line-height: 16px;
      margin-top: 16px;

      @include XGA {
        margin-left: 10%;
      }

      @include HD {
        font-size: 15px;
        line-height: 20px;
      }
    }

    @include HD {
      font-size: 28px;
      line-height: 32px;
    }
  }

  video,
  .placeholder {
    display: block;
    margin: 0 auto;
    width: 100%;

    @include XGA {
      margin-top: 48px;
      width: 80%;
    }

    @include HD {
      margin-top: 64px;
    }
  }

  .placeholder {
    background-color: #000;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 70%;
    background-image: url(../../../img/placeholder-bg.svg);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 30px 8px;

    @include vHD {
      aspect-ratio: 16/8;
    }

    h3 {
      font-size: 24px;
      line-height: 24px;
      margin: 0;
      font-weight: 900;
      text-transform: uppercase;

      @include HD {
        font-size: 80px;
        line-height: 90px;
      }
    }

    p {
      font-size: 16px;
      line-height: 20px;
      margin: 16px 0;

      @include HD {
        font-size: 28px;
        line-height: 32px;
      }
    }

    .button {
      background-color: #ed8002;
      border: 0;
      border-radius: 24px;
      color: #fff;
      font-weight: 900;
      text-decoration: none;
      padding: 12px 24px;
      text-align: center;

      &:hover {
        background: $orange;
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.16);
      }

      &:active {
        background: $darkorange;
      }

      @include HD {
        margin-top: 40px;
      }
    }
  }

  .video-holder {
    position: relative;

    video:hover + .re-play-icon {
      opacity: 0.8;
    }

    &.paused {
      video {
        cursor: pointer;
      }
    }

    &.paused .re-play-icon {
      &::before {
        content: url(./img/play.svg);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
      }
    }

    &.ended .re-play-icon {
      &::before {
        content: url(./img/replay.svg);
      }
    }
  }
}
